@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
/* @import url("./animations.css"); */
@import url("./transitions.css");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  --primary: #0e0e0e;
  --secondary: #f12602;
  --white: #fafafa;
  --dark-white: #c1c1c1;
  --dark: #151515;
}

body {
  background-color: var(--primary);
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-family: "Alumni Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--white);
}

h1,
h2 {
  line-height: 0.8;
}

h1 {
  font-size: 10.4rem;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 7.4rem;
  font-weight: 700;
}

h3 {
  font-size: 4.8rem;
  font-weight: 700;
}

h4 {
  font-size: 3.04rem;
  font-weight: 700;
}

h5 {
  font-size: 2.4rem;
  font-weight: 700;
}

h6 {
  font-size: 2rem;
  font-weight: 700;
}

a,
button {
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
}

button {
  background-color: var(--secondary);
  border: none;
  padding: 0.75em 2em;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s,
    color ease-in-out 0.3s;
}

button:hover {
  background-color: var(--white);
  color: var(--secondary);
}

button:active,
button:focus-visible {
  border: 1px solid var(--secondary);
  color: var(--secondary);
  background-color: transparent;
}

a:hover,
a:focus-visible {
  color: var(--secondary);
}

a:active {
  opacity: 0.75;
}

p,
input,
textarea,
span,
li {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  color: var(--white);
  font-size: 1.6rem;
}

input,
textarea {
  background-color: var(--dark);
  color: var(--white);
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all ease-in-out;
  transition-duration: 0.3s;
}

input:focus,
textarea:focus {
  border: 1px solid var(--secondary);
  outline: none;
  box-shadow: inset 0px 0px 15px var(--secondary), 0px 0px 15px var(--secondary);
}

a {
  text-decoration: none;
  transition: all ease-in-out;
  transition-duration: 0.15s;
}

.container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}

.prevent-scroll {
  overflow: hidden;
}

::-webkit-scrollbar {
  background-color: var(--dark);
}

::-webkit-scrollbar-button {
  background-color: var(--dark);
  cursor: pointer;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 2px;
  cursor: pointer;
}

@media (max-width: 765px) {
  h1 {
    font-size: 7.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2 {
    font-size: 4.4rem;
    font-weight: 700;
  }

  h3 {
    font-size: 3.8rem;
    font-weight: 700;
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 700;
  }

  h5 {
    font-size: 2rem;
    font-weight: 700;
  }

  h6 {
    font-size: 1.8rem;
    font-weight: 700;
  }
}
