/* GENERAL */
.carousel {
  margin-top: 2em;
  position: relative; 
  overflow: hidden; 
}

/* CONTENT */
.carousel > .content {
  display: flex;
  transition: transform 0.5s ease-in-out; 
  width: 100%; 
  will-change: transform; 
  position: relative; 
}

.carousel > .content > .slide > h2 {
  margin-bottom: 0.125em;
}

.carousel > .content > .slide {
  min-width: 100%; 
  box-sizing: border-box; 
  text-align: center; 
}

.carousel > .content > .slide > h2 {
  color: var(--secondary);
}

/* CONTROLS */
.carousel > .controls {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

.carousel > .controls > .dot {
  display: inline-block;
  background-color: var(--white);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.carousel > .controls > .dot + .dot {
  margin-left: 1em;
}

.carousel > .controls > .highlighted-dot {
  background-color: var(--secondary);
}
