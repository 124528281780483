/* OVERLAY */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  animation: overlayAnim 0.75s 1;
}

@keyframes overlayAnim {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }

  to {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
}

/* MODAL */
.product-modal {
  position: fixed;
  height: 80vh;
  width: 80%;
  z-index: 10000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark);
  border-radius: 5px;
  padding: 4em;
  overflow-y: auto;
  max-width: 700px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  animation: modalAnim 0.35s 1;
}

@keyframes modalAnim {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.product-modal > .close {
  text-align: right;
  transform: translate(1.5em, -1.8em);
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.product-modal > .close:hover {
  color: var(--secondary);
}

.product-modal > .product-image {
  display: flex;
  justify-content: center;
  background: linear-gradient(to top, var(--secondary), transparent);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  margin-top: 1em;
}

.product-modal > .product-image > img {
  width: 30rem;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
}

.product-modal > h3 {
  margin: 0.2em 0;
  text-align: center;
}

/* TABS */
.product-modal > .tabs {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--primary);
  border-radius: 5px;
  margin-bottom: 1em;
  position: relative;
}

.product-modal > .tabs > h4 {
  flex: 1;
  text-align: center;
  padding: 0.2em 0;
  cursor: pointer;
  transition: background-color 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1;
}

.product-modal > .tabs > .highlight {
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: var(--secondary);
  left: 0;
  opacity: 0;
  transition: transform 0.35s ease-in-out, border-radius 0.35s ease-in-out;
}

.highlight-left {
  transform: translateX(0);
  opacity: 1 !important;
  border-radius: 5px 0 0 5px;
}

.highlight-right {
  transform: translateX(100%);
  opacity: 1 !important;
  border-radius: 0 5px 5px 0;
}

/* .selected-tab {
  background-color: var(--secondary);
} */
