/* FOOTER */
footer > .background {
  position: relative;
  overflow: hidden;
}

footer > .background > img {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

footer > .background > .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  padding: 6em 0;
}

.icon {
  width: 3rem;
  transform: translateY(0.2em);
}

.icon-alternate {
  width: 3rem;
  transform: translateY(0.5em);
}

/* LEFT SECTION */

footer > .background > .container > .left-section > p {
  margin-top: 1em;
}

footer > .background > .container > .left-section > .logo {
  width: 35rem;
}

/* RIGHT SECTION -> TOP SECTION */

footer > .background > .container > .right-section > .top-section {
  display: flex;
  justify-content: center;
  gap: 3em;
}

/* RIGHT SECTION -> BOTTOM SECTION */

footer > .background > .container > .right-section > .bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3em;
}

/* COPYRIGHT */

footer > .background > .copyright {
  text-align: center;
  background-color: var(--primary);
  padding: 1em 0;
}

@media (max-width: 930px) {
  footer > .background > .container {
    flex-direction: column;
  }
}

@media (max-width: 820px) {
  footer > .background > .container > .left-section  {
    text-align: center;
  }

  footer > .background > .container > .right-section > .top-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  footer > .background > .container > .right-section > .bottom-section {
    gap: 3em;
  }
}

@media (max-width: 515px) {
  footer > .background > .container > .right-section > .bottom-section {
    flex-direction: column;
    gap: 1em;
  }
}
