/* GENERAL */
.products {
  padding: 6em 0;
}

.products > h6 {
  color: var(--secondary);
  margin-bottom: 0.2em;
}

.products > h1 {
  margin-bottom: 0.2em;
}

.products > h1,
.products > h6 {
  text-align: center;
}

.products > .data-list-input {
  margin-bottom: 4em;
  text-align: center;
}
