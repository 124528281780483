/* GENERAL */
.not-found {
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-found > h1 {
  color: var(--secondary);
}

.not-found > h2 {
  margin-bottom: 0.2em;
}

.not-found > * {
  text-align: center;
}

.not-found > a {
  margin-top: 1em;
}
