/* INPUT */
.data-list-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-list-input > input {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  flex: 0.85;
  text-align: center;
  max-width: 60rem;
}

.data-list-input > data-list > option {
  color: red;
}
