/* GENERAL */
.brand-message {
  padding: 6em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5em;
}

.brand-message > .right-section {
  flex: 0.25;
  min-width: 20rem;
}

.brand-message > .left-section {
  flex: 0.75;
}

/* LEFT SECTION */
.brand-message > .left-section > h1,
.brand-message > .left-section > h6,
.brand-message > .left-section > p {
  margin-bottom: 0.2em;
}

.brand-message > .left-section > h6 {
  color: var(--secondary);
}

/* RIGHT SECTION */
.brand-message > .right-section > img {
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
}

@media (max-width: 768px) {
  .brand-message {
    flex-direction: column-reverse;
  }

  .brand-message > .right-section > img {
    max-width: 20rem;
    min-width: 0;
  }
}
