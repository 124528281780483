@import "~leaflet/dist/leaflet.css";

.leaflet-container {
  border-radius: 5px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
}

.leaflet-container:hover {
  box-shadow: 0px 0px 30px var(--secondary), inset 0px 0px 30px var(--secondary);
  border: 1px solid var(--secondary);
}

.leaflet-control-zoom-in > span,
.leaflet-control-zoom-out > span {
  background-color: white;
  color: black;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #f0f0f0;
}
