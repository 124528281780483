/* GENERAL */
.core-values {
  padding: 6em 0;
  background-color: var(--dark);
  width: 100%;
}

.core-values > .container > h1,
.core-values > .container > h6 {
  text-align: center;
}

.core-values > .container > h6 {
  margin-bottom: 0.2em;
  color: var(--secondary);  
}

.core-values > .container > .cards {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 45rem));
  gap: 3em;
  justify-content: center;
}
