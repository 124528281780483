/* GENERAL */
.info-section {
  text-align: center;
  padding: 6em 0;
}

.info-section > h6 {
  color: var(--secondary);
  margin-bottom: 0.2em;
}

.info-section > p {
  margin-bottom: 3em;
}

/* ICONS GRID */
.info-section > .icons-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  margin: 4em 0;
  gap: 3em;
}

.info-section > .icons-grid > .phone,
.info-section > .icons-grid > .email,
.info-section > .icons-grid > .website,
.info-section > .icons-grid > .location {
  display: flex;
  align-items: center;
  gap: 1em;
}

.info-section > .icons-grid .icon {
  width: 4rem;
  height: 4rem;
}

.info-section > .socials > .icons > a > img {
  background-color: var(--secondary);
  padding: 0.5rem 1rem;
  height: 100%;
}

.info-section > .socials > .icons {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  gap: 1.5em;
}

@media (max-width: 950px) {
  .info-section > .icons-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 690px) {
  .info-section > .icons-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
