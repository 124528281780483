header {
  padding: 1.5em 0em;
  position: fixed;
  background-color: var(--primary);
  width: 100%;
  z-index: 1001;
}

header > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header > .container > .right-section > .lg > a + a {
  margin-left: 2em;
}

header > .container > .left-section {
  width: 25rem;
}

header > .container > .right-section > .sm {
  display: none;
  transition: rotate 0.35s ease-in-out;
  cursor: pointer;
}

header > .container > .right-section > .sm > img {
  width: 3.5rem;
}

header > .bottom-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  visibility: hidden;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease-in-out;
}

@media (max-width: 768px) {
  header > .container > .right-section > .sm {
    display: block;
  }

  header > .container > .right-section > .lg {
    display: none;
  }
}
