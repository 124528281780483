.progress-wrapper {
  position: relative;
  display: inline-block;
}

.progress-bg {
  stroke-linecap: round;
}

.progress-bar {
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
