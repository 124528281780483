/* GENERAL */
.text-card > * {
  text-align: center;
}

.text-card > h3 {
  color: var(--secondary);
}

.text-card {
  border: 1px solid var(--white);
  padding: 4em;
  border-radius: 10px;
  box-shadow: inset 10px 10px 20px transparent,
    10px 10px 20px rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.text-card:hover {
  box-shadow: inset 0px 0px 40px var(--secondary), 0px 0px 40px var(--secondary);
  border: 1px solid var(--secondary);
}
