/* GENERAL */
.ceo-message {
  padding: 6em 0;
  background-color: var(--dark);
}

.ceo-message > .container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 5em;
}

/* RIGHT SECTION */
.ceo-message > .container > .right-section {
  flex: 0.25;  
}

.ceo-message > .container > .right-section > img {
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  background: linear-gradient(to top, var(--secondary), transparent);
}

/* LEFT SECTION */
.ceo-message > .container > .left-section {
  flex: 0.75;  
}

.ceo-message > .container > .left-section > .quote-icon {
  width: 5rem;
  rotate: 360deg;
  margin-top: 2em;
  margin-bottom: 0.5em;
}

.ceo-message > .container > .left-section > .quote-icon-alternate {
  width: 5rem;
  rotate: 180deg;
  margin-left: auto;
  margin-top: 0.5em;
}

.ceo-message > .container > .left-section > p:first-of-type {
  margin-bottom: 0.25em;
}

.ceo-message > .container > .left-section > p:last-of-type {
  margin-top: 0.45em;
}

.ceo-message > .container > .left-section > h6 {
  color: var(--secondary);
  margin-bottom: 0.2em;
}

@media (max-width: 768px) {
  .ceo-message > .container {
    flex-direction: column-reverse;
  }

  .ceo-message > .container > .right-section > img {
    max-width: 30rem;
    min-width: 0;
  }
}
