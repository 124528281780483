/* General */
.our-mission {
  padding: 6em 0;
}

.our-mission > * {
  text-align: center;
}

.our-mission > h6 {
  color: var(--secondary);
  margin-bottom: 0.2em;
}