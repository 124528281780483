/* GENERAL */
.locations-section {
  background-color: var(--dark);
  padding: 6em 0;
}

.locations-section > .container > h1,
.locations-section > .container > h6 {
  text-align: center;
  margin-bottom: 0.2em;
}

.locations-section > .container > h6 {
  color: var(--secondary);
}
