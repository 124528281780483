/* GENERAL */
.general-hero {
  padding: 10em 0;
  background: linear-gradient(to bottom, var(--secondary), transparent);
}

.general-hero > .container > * {
  text-align: center;
  filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, 0.5));
}

.general-hero > .container > h1 {
  margin-bottom: 0.2em;
}