.transition-from-top {
  animation: transition-top both ease-in-out 0.65s 1;
}

@keyframes transition-top {
  from {
    opacity: 0;
    transform: translateY(100px) scale(0.75);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  } 
}

.transition-from-bottom {
  animation: transition-bottom both ease-in-out 0.65s 1;
}

@keyframes transition-bottom {
  from {
    opacity: 0;
    transform: translateY(-100px) scale(0.75);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  } 
}

.transition-from-left {
  animation: transition-left both ease-in-out 0.65s 1;
}

@keyframes transition-left {
  from {
    opacity: 0;
    transform: translateX(-100px) scale(0.75);
  }

  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  } 
}

.transition-from-right {
  animation: transition-right both ease-in-out 0.65s 1;
}

@keyframes transition-right {
  from {
    opacity: 0;
    transform: translateX(100px) scale(0.75);
  }

  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  } 
}
