/* GENERAL */

.introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;
  padding: 8em 0;
}

.introduction > .left-section {
  flex: 1;
}

.introduction > .right-section {
  flex: 1;
}

/* LEFT SECTION */
.introduction > .left-section > img {
  width: 50%;
}

.introduction > .left-section > img:first-of-type {
  margin-top: 3.5em;
}

.introduction > .left-section {
  display: flex;
  gap: 1em;
}

/* RIGHT SECTION */

.introduction > .right-section > h6 {
  color: var(--secondary);
}

.introduction > .right-section > * {
  margin-bottom: 0.2em;
}

/* Right Section -> Bottom Section */

.introduction > .right-section > .bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
}

.introduction > .right-section > .bottom-section > * {
  flex: 1;
}

.introduction > .right-section > .bottom-section > .mission,
.introduction > .right-section > .bottom-section > .vision {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.introduction > .right-section > .bottom-section > .mission > .right-section,
.introduction > .right-section > .bottom-section > .vision > .right-section {
  flex: 1;
}

@media (max-width: 950px) {
  .introduction {
    flex-direction: column;
  }

  .introduction > .left-section > img {
    max-width: 30rem;
    min-width: 0;
  }
}

@media (max-width: 725px) {
  .introduction > .right-section > .bottom-section {
    flex-direction: column;
    gap: 2em;
  }
}
