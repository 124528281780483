/* General */
.statistics {
  background-color: var(--dark);
  padding: 8em 0;
}

.statistics > .container > * {
  text-align: center;
  margin-bottom: 0.2em;
}

.statistics > .container > h4 {
  color: var(--secondary);
}

/* Circles */

.statistics > .container > .circles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  margin-top: 4em;
}

@media (max-width: 1135px) {
  .statistics > .container > .circles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .statistics > .container > .circles > * {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .statistics > .container > .circles {
    grid-template-columns: repeat(1, 1fr);
  }
}
