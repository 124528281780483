/* GENERAL */
.product-card {
  background-color: var(--dark);
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 30rem;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: inset 10px 10px 20px transparent,
    10px 10px 20px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  height: 100%;
}

.product-card:hover {
  box-shadow: inset 0px 0px 40px var(--secondary), 0px 0px 40px var(--secondary);
  border: 1px solid var(--secondary);

  .see-more {
    color: var(--secondary);
  }
}

/* PRODUCT IMAGE */
.product-card > .product-image {
  background: linear-gradient(to top, var(--secondary), transparent);
}

.product-card > .product-image > img {
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

/* PRODUCT DESCRIPTION */
.product-card > .product-info > .product-description {
  color: var(--dark-white);
}


/* SEE MORE */
.product-card > .see-more {
  margin-top: auto;
  transition: color ease-in-out 0.3s;
  margin-left: 0;
}
