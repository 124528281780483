/* GENERAL */

.cta {
  padding: 10em 0;
  background: linear-gradient(to top, var(--secondary), var(--dark));
}

.cta > .container > * {
  text-align: center;
  margin-bottom: 0.2em;
}

.cta > .container > a > button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}
