/* GENERAL */

.hero > .background {
  position: relative;
  height: fit-content;
  width: 100%;
  padding: 7em 0;
}

.hero > .background > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero > .background > .container {
  position: relative;
}

.hero > .background > .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
}

.hero > .background > .container > .left-section,
.hero > .background > .container > .right-section {
  flex: 1;
}

/* LEFT SECTION */
.hero > .background > .container > .left-section > * {
  margin-bottom: 0.2em;
}

.hero > .background > .container > .left-section > a > button {
  margin-top: 2em;
}

.hero > .background > .container > .left-section > h6 {
  color: var(--secondary);
}

/* RIGHT SECTION */

.hero > .background > .container > .right-section {
  flex: 1;
  display: flex;
  gap: 1em;
}

.hero > .background > .container > .right-section > img {
  width: 50%;
}

.hero > .background > .container > .right-section > img:first-of-type {
  margin-top: 3.5em;
}

@media (max-width: 950px) {
  .hero > .background > .container {
    flex-direction: column;
  }  

  .hero > .background > .container > .right-section > img {
    max-width: 30rem;
    min-width: 0;
  }
}
